import React from "react"
import "./SideNav.scss"
import PropTypes from "prop-types"
import Servers from '../images/servers-icon.svg'
import Containers from '../images/containers-icon.svg'
import VirtualM from '../images/vm-icon.svg'
import Cloud from '../images/cloud-icon.svg'
import { useIntl } from "gatsby-plugin-intl"

const SideNav = ({ type, active }) => {
  const intl = useIntl()
  const navItems = [
    {
      title: intl.formatMessage({ id: "servers.seo" }),
      icon: <Servers />,
      ariaLabel: 'servers'
    },
    {
      title: intl.formatMessage({ id: "vms.seo" }),
      icon: <VirtualM />,
      ariaLabel: 'vm'
    },
    {
      title: intl.formatMessage({ id: "containers.seo" }),
      icon: <Containers/>,
      ariaLabel: 'containers'
    },
    {
      title: intl.formatMessage({ id: "hosting.seo" }),
      icon: <Cloud />,
      ariaLabel: 'cloud'
    }
  ];

  const sideNav = navItems.map(
    (item, index) => {
      const activeItem = active === index ? 'active' : '';
      return (<li key={index} className={activeItem} aria-label={item.ariaLabel}><span>{item.title}</span>{item.icon}</li>)
    }
  );

  return (
    <nav className={`side-nav`}>
      <ul className={type}>
        {sideNav}
      </ul>
    </nav>
  );
}

SideNav.propTypes = {
  type: PropTypes.string,
  active: PropTypes.number
}

SideNav.defaultProps = {
  type: ``,
  active: 0
}

export default SideNav
