import React from "react"
import Layout from "../components/layout"
import Containers from "../components/Containers"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"

const ContainersPage = () => {
  const intl = useIntl()
  return (
    <Layout headerMod="header-dark" btnMod="--dark">
      <SEO title={intl.formatMessage({ id: "containers.seo" })} />
      <Containers showBtn={false} />
      <section className="product-info main-product-section" header-mod="header-internal" btn-mod="--gradient">

      </section>
    </Layout>
  );
}

export default ContainersPage