import React from "react"
import { Link } from "gatsby"
import "./Containers.scss"
import SideNav from "./SideNav"
import BgImg from "../images/bg-image.svg"
import Highlight from "../images/containers-highlight-img.svg"
import PropTypes from "prop-types"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"

const Containers = ({ showBtn, showSideNav }) => {
  const btn = () => {
    if (showBtn) {
      return (
        <div className="containers-contact">
          <Link to="/containers/" className="vbtn vbtn--dark"><FormattedMessage id="buttons.learn_more" /></Link>
        </div>
      )
    }
  }

  const sideNav = () => {
    if ( showSideNav ) {
      return <SideNav type="dark" active={2} section="containers" />;
    }
  }

  return (
    <section className="containers-section side-nav--dark main-product-section" header-mod="header-dark" btn-mod="--dark" cookie="dark">
      {sideNav()}
      <BgImg className="section__bg-image"/>
      <div className="grid-wrapper">
        <div className="section__highlight-container">
          <Highlight className="section__highlight-image" />
        </div>
        <div className="section-header-container">
          <FormattedHTMLMessage id="containers.title" />
        </div>
        <div className="product-characteristics-container">
          <ul className="product-characteristics">
            <li><FormattedMessage id="containers.product_highlights.high1" /></li>
            <li><FormattedMessage id="containers.product_highlights.high2" /></li>
            <li><FormattedMessage id="containers.product_highlights.high3" /></li>
          </ul>
        </div>
        <div className="product-pricing">
          <h4><FormattedMessage id="containers.starting_at" /></h4>
          <h3><FormattedMessage id="containers.starting_price" /></h3>
        </div>
        {btn()}
      </div>
    </section>
  )
}

Containers.propTypes = {
  showBtn: PropTypes.bool,
  showSideNav: PropTypes.bool
}

export default Containers
